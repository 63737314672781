import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Col, Row } from "react-bootstrap"
import { AuthContainer } from "../../common/AuthContainer"
import { Search } from "../../components/Search"
import { UserList } from "./userList"
import { useUserProfileContext } from "../../contexts/UserProfileContext"
import { useAuthContext } from "../../contexts/AuthContext"
import { debounce } from "../../utils"
import { CustomButton } from "../../components/CustomButton"
import { CustomSpinner } from "../../components/CustomSpinner"
import "./styles.scss"

export const AdminUserList = () => {
  const nav = useNavigate()
  const { isLoading, users, getUserList } = useUserProfileContext()
  const { isLoading: loading, userLogout } = useAuthContext()
  const [searchText, setSearchText] = useState("")
  const onChangeSearch = debounce(text => setSearchText(text), 300)

  useEffect(() => {
    getUserList({ params: { search: searchText } })
  }, [searchText])

  return (
    <AuthContainer
      title="User List"
      className="admin-user-list-container login"
    >
      <Row className="justify-content-between">
        <Col xs="12" md="6" lg="6" className="mb-3">
          <Search
            placeholder="Search via user name, email or store url"
            onChange={onChangeSearch}
          />
        </Col>
        <Col xs="12" md="6" lg="2" className="mb-3">
          <CustomButton
            label="Sign Out"
            type="button"
            disabled={loading}
            onClick={() => {
              userLogout().then(() => {
                nav("/login")
              })
            }}
          />
        </Col>
      </Row>
      <UserList users={users} />
      <CustomSpinner loading={isLoading || loading} />
    </AuthContainer>
  )
}
