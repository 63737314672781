import React, { useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { Controller } from "react-hook-form"
import { assets } from "../../utils"
import "./InputFieldPassword.scss"

export const InputFieldPassword = ({
  label,
  name,
  type,
  placeholder,
  control,
  trigger,
  rules,
  ...moreProps
}) => {
  const [open, setOpen] = useState(false)
  const [inputType, setInputType] = useState("password")
  useEffect(() => {
    setInputType(type)
  }, [type])

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, name, ref },
        formState: { errors }
      }) => {
        return (
          <Form.Group className="is-invalid mb-3 position-relative">
            <Form.Label>{label}</Form.Label>
            <Form.Control
              type={inputType}
              placeholder={placeholder}
              onChange={async value => {
                onChange(value)
                trigger(name)
              }}
              onBlur={onBlur}
              ref={ref}
              className={`${errors[name] ? "is-invalid" : ""}`}
              {...moreProps}
            />
            {open ? (
              <img
                className="eye-icon eye-open"
                src={assets.EyeOpenIcon}
                onClick={() => {
                  setInputType("password")
                  setOpen(false)
                }}
              />
            ) : (
              <img
                className="eye-icon eye-closed"
                src={assets.EyeClosedIcon}
                onClick={() => {
                  setInputType("text")
                  setOpen(true)
                }}
              />
            )}
            {errors[name]?.message && (
              <Form.Control.Feedback type="invalid">
                {errors[name]?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        )
      }}
    />
  )
}
