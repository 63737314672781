import Request from "./axiosInstance"

const tokenInterceptor = Request.interceptors.request.use(
  req => {
    const token = localStorage.getItem("token")
    const xUserAccount = localStorage.getItem("xUserAccount")
    if (xUserAccount) {
      req.headers["X-User-Account"] = `${xUserAccount}`
    }
    if (!!token) {
      req.headers["Authorization"] = `Token ${token}`
    }
    return req
  },
  null,
  { synchronous: true }
)

const catchErrors = err => {
  if ([400, 500, 403, 401].find(e => e == err?.response?.status)) {
    window?.customErrorCallback?.(
      err.response.data.message,
      err?.response?.status
    )
  }
  throw err
}

window.addEventListener("storage", () => {
  if (localStorage.getItem("token")) {
    // Request.interceptors.request.eject(tokenInterceptor)
    Request.interceptors.request.use(tokenInterceptor)
  }
})

export const get = (url, config) => Request.get(url, config).catch(catchErrors)

export const post = (url, data, config) =>
  Request.post(url, data, config).catch(catchErrors)

export const put = (url, config) => Request.put(url, config).catch(catchErrors)

export const patch = (url, data, config) =>
  Request.patch(url, data, config).catch(catchErrors)

export const del = (url, data, config) =>
  Request.delete(url, data, config).catch(catchErrors)
