import Card from "react-bootstrap/Card"
import { CustomButton } from "../../components/CustomButton"
import "./subscription.scss"

export function Subscription({
  id,
  name = "",
  description = "",
  subTitle,
  price = "19.99",
  most_popular = false,
  onClickSubscription,
  currentPlanId,
  status,
  mt
}) {
  return (
    <Card className="Subscription-card position-relative" key={name}>
      <Card.Body className="align-items-center justify-content-around">
        <div className="text-center mb-3">
          <Card.Subtitle className="mb-1">{name}</Card.Subtitle>
          <Card.Text className="mb-2 text-muted" style={textStyles}>
            {subTitle && <div className="mb-3">{subTitle}</div>}
          </Card.Text>
        </div>
        {name !== "Elite" && (
          <div className="text-center mb-5">
            <Card.Title className="price">{`$${price}`}</Card.Title>
          </div>
        )}

        {description && (
          <Card.Text
            className={`mb-5 ${name === "Elite" ? `text-center ${mt}` : ""}`}
          >
            {description?.split(",").map(line => (
              <>
                <span>{line}</span> <br />
              </>
            ))}
            {name === "Elite" && <br />}
            {name === "Elite" && <br />}
          </Card.Text>
        )}

        <CustomButton
          label={`${name === "Elite" ? "Contact Us" : "Get Started"}`}
          variant="primary"
          type="button"
          disabled={status !== "I" && currentPlanId === id}
          onClick={() => {
            if (id) {
              onClickSubscription(id)
            }
          }}
          backgroundColor="white"
          styles={{ fontSize: "14px", lineHeight: "14px" }}
        />
      </Card.Body>
      {most_popular && (
        <Card.Subtitle className="most-popular mb-1">
          Most Popular
        </Card.Subtitle>
      )}
    </Card>
  )
}

const textStyles = {
  fontStyle: "normal",
  fontWeight: "300",
  fontSize: "12px",
  lineHeight: "12px"
}
