import React from "react"
import { CustomModal } from "../../components/CustomModal"
import { Col, Row } from "react-bootstrap"
import { CustomButton } from "../../components/CustomButton"

export const SyncPopup = ({ show, onHide, isLoading, onClick }) => {
  return (
    <CustomModal size="md" showHeader={true} show={show} onHide={onHide}>
      <Row className="p-3">
        <Col>
          <h5 className="title">Authorizing</h5>

          <p className="sub-title">
            Please click on "Add App" in Shopify. After completing the
            authorization, click on "Sync", and we will refresh the page for
            you. If you see "App Installed" in Shopify, it means that this
            Shopify account is already linked to Smoke Source.
          </p>
        </Col>
      </Row>
      <Row className="add-card-payment justify-content-center">
        <Col xs="4">
          <CustomButton
            label="Sync"
            variant="primary"
            type="button"
            loading={isLoading}
            onClick={onClick}
          />
        </Col>
      </Row>
    </CustomModal>
  )
}
