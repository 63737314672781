import React from "react"
import { InputFormGroup } from "../../components/InputField"
import { Col, Row } from "react-bootstrap"
import { Checkbox } from "../../components/Checkbox"

export function PriceListForm({ control, trigger, fields }) {
  return (
    <>
      {fields.map((_, index) => (
        <Row className="align-items-baseline">
          <Col xs="1" lg="1">
            <Checkbox
              key="out-of-stock"
              label=""
              name={`my_store_product_variants.${index}.checked`}
              type="checkbox"
              control={control}
              trigger={trigger}
              defaultChecked={fields[index].checked}
              // disabled={isLoading}
            />
          </Col>
          <Col xs="6" lg="6">
            {fields[index].title}
          </Col>
          <Col xs="5" lg="5">
            <InputFormGroup
              key={index}
              label=""
              defaultValue={fields[index].price}
              name={`my_store_product_variants.${index}.price`}
              index={index}
              control={control}
              trigger={trigger}
            />
          </Col>
          <hr />
        </Row>
      ))}
    </>
  )
}
