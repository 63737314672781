export const formData = [
  {
    label: "Password",
    name: "new_password1",
    type: "password",
    placeholder: "Please enter a password",
    rules: { required: "Password is required!" }
  },
  {
    label: "Re-enter Pasword",
    name: "new_password2",
    type: "password",
    placeholder: "Please re-enter the password",
    rules: { required: "Re-enter Pasword is required!" }
  }
]
