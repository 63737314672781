import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { CustomCard } from "../../components/CustomCard"
import React, { useEffect, useState } from "react"
import { CustomButton } from "../../components/CustomButton"
import { Row, Col } from "react-bootstrap"
import { InputField } from "../../components/InputField"
import { useForm } from "react-hook-form"
import { useAuthContext } from "../../contexts/AuthContext"
import { assets } from "../../utils"
import "./ordersdetails.scss"

export const NoteCard = ({
  order_note,
  order,
  addOrderNote,
  updateOrderNote,
  getOrder,
  deleteOrderNote,
  isLoading
}) => {
  const { notifySuccess } = useAuthContext()
  const [note, setNote] = useState("")
  const [editMode, setEditMode] = useState(false)

  const formSchema = Yup.object().shape({
    note: Yup.string().required("Note is required")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { handleSubmit, control, trigger, reset, setValue, clearErrors } =
    useForm(formOptions)
  const onSubmit = vals => {
    const payload = { ...vals }
    payload.order = order
    if (order_note?.id) {
      return updateOrderNote(order_note.id, vals).then(() => {
        setNote("")
        reset()
        getOrder(order)
        notifySuccess("Note updated successfully!")
        setEditMode(false)
      })
    }
    addOrderNote(payload).then(() => {
      setNote("")
      reset()
      getOrder(order)
      notifySuccess("Note sent successfully!")
    })
  }

  useEffect(() => {
    if (order_note?.note) {
      setNote(order_note.note)
      setValue("note", order_note.note)
    } else {
      setNote("")
      setValue("note", "")
    }
  }, [order_note])

  return (
    <CustomCard
      title="Order Note"
      icon={
        order_note?.note && (
          <Row>
            <Col>
              <img
                src={assets.DeleteIcon}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  deleteOrderNote(order_note.id).then(() => {
                    setNote("")
                    reset()
                    getOrder(order)
                    notifySuccess("Note deleted successfully!")
                  })
                }
              />
            </Col>
            <Col>
              <img
                src={assets.EditIcon}
                style={{ cursor: "pointer" }}
                onClick={() => setEditMode(true)}
              />
            </Col>
          </Row>
        )
      }
      className="note-info"
    >
      {/* <Row>
        <p>{order_note?.note}</p>
      </Row> */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="mt-3">
          <Col xs="12">
            <InputField
              label=""
              name="note"
              as="textarea"
              placeholder="Please enter your order notes here..."
              value={note}
              disabled={order_note?.note ? !editMode : false}
              control={control}
              trigger={trigger}
              onChange={e => {
                const { value } = e.target
                if (value) {
                  clearErrors("note")
                }
                setValue("note", value)
                setNote(value)
              }}
            />
          </Col>
        </Row>
        {order_note?.note && editMode ? (
          <Row className="justify-content-end">
            <Col xs="4" md="2">
              <CustomButton
                label="Cancel"
                variant="outline-primary"
                type="button"
                disabled={isLoading}
                onClick={() => {
                  setNote(order_note?.note)
                  setEditMode(false)
                }}
              />
            </Col>
            <Col xs="4" md="2">
              <CustomButton
                label="Save"
                variant="primary"
                type="submit"
                disabled={isLoading}
              />
            </Col>
          </Row>
        ) : (
          !order_note?.note && (
            <Row className="justify-content-end">
              <Col xs="4" md="2">
                <CustomButton
                  label="Cancel"
                  variant="outline-primary"
                  type="button"
                  disabled={isLoading}
                  onClick={() => {
                    reset({
                      message: ""
                    })
                    setNote("")
                  }}
                />
              </Col>
              <Col xs="4" md="2">
                <CustomButton
                  label="Save"
                  variant="primary"
                  type="submit"
                  disabled={isLoading}
                />
              </Col>
            </Row>
          )
        )}
      </form>
    </CustomCard>
  )
}
