import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { AuthContainer } from "../../common/AuthContainer"
import { useForm } from "react-hook-form"
import { formData } from "./formData"
import { Col, Row } from "react-bootstrap"
import { useAuthContext } from "../../contexts/AuthContext"
import { InputFieldPassword } from "../../components/InputFieldPassword"
import { useLocation, useNavigate } from "react-router-dom"
import { CustomButton } from "../../components/CustomButton"

export const ResetPassword = () => {
  const nav = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const { isLoading, resetPasswordConfirm, notifySuccess } = useAuthContext()

  const formSchema = Yup.object().shape({
    new_password1: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at 6 char long")
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Password must be at least 8 letters, one capital & small letter, one digit and at least one special symbol from [ ! @ # $ % ^ & * ]"
      ),
    new_password2: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("new_password1")], "Passwords does not match")
  })
  const formOptions = { mode: "onTouched", resolver: yupResolver(formSchema) }
  const { handleSubmit, control, trigger } = useForm(formOptions)

  const onSubmit = vals => {
    const payload = {
      ...vals,
      uid: searchParams.get("uid"),
      token: searchParams.get("token")
    }
    resetPasswordConfirm(payload).then(response => {
      notifySuccess(`${response.detail}`)
      nav("/login")
    })
  }

  const renderFormData = () => {
    return formData.map(({ label, name, type, placeholder, rules }, index) => (
      <InputFieldPassword
        key={index}
        label={label}
        name={name}
        type={type}
        placeholder={placeholder}
        control={control}
        trigger={trigger}
        rules={rules}
      />
    ))
  }

  return (
    <AuthContainer title="Reset Password" className="signup">
      <div className="signup-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          {renderFormData()}
          <Row className="justify-content-center mt-5">
            <Col xs="11" className="mb-3">
              <CustomButton
                label="Confirm"
                variant="primary"
                type="submit"
                loading={isLoading}
              />
            </Col>
          </Row>
        </form>
      </div>
    </AuthContainer>
  )
}
