import React from "react"
import { Col, Row } from "react-bootstrap"
import { Product } from "./product"

export const Products = ({
  products,
  onClickProduct,
  setProductId,
  setIdToRemove,
  setShowConfirm,
  selectedProducts,
  setSelectedProducts,
  isLoading,
  myProducts
}) => {
  return (
    <Row className="products-wrapper pb-5">
      {products.map(product => (
        <Col
          xs="12"
          md="4"
          xl="3"
          className="mb-3"
          key={`${product.id}${product.created_at}`}
        >
          <Product
            key={product.id}
            product={product.id}
            {...product}
            onClick={() => onClickProduct(product.id)}
            onClickAdd={e => {
              e.stopPropagation()
              setProductId(product.id)
            }}
            onClickRemove={e => {
              e.stopPropagation()
              setIdToRemove(product.id)
              setTimeout(() => setShowConfirm(true), 0)
            }}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            isLoading={isLoading}
            myProducts={myProducts}
          />
        </Col>
      ))}
    </Row>
  )
}
