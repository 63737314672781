import Spinner from "react-bootstrap/Spinner"
import "./spinner.scss"

export function CustomSpinner({ loading }) {
  return (
    <div className="spinner-wrapper">
      {loading && (
        <Spinner animation="border" role="status" variant="secondary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  )
}
