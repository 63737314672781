import React, { useState } from "react"
import { AuthContainer } from "../../common/AuthContainer"
import { useAuthContext } from "../../contexts/AuthContext"
import { assets, formatDateForDB } from "../../utils"
import { useLocation, useNavigate } from "react-router-dom"
import { PaymentForm } from "../../components/PaymentForm"
import { useUserProfileContext } from "../../contexts/UserProfileContext"
import { CustomModal } from "../../components/CustomModal"

export const Payment = () => {
  const nav = useNavigate()
  const location = useLocation()
  const { notifySuccess, confirmPayment } = useAuthContext()
  const { addPaymentProfile, addSubscriptionRequest, getLoginUserProfile } =
    useUserProfileContext()
  const [loading, setIsLoading] = useState(false)
  const [show, setShow] = useState(true)
  const onHide = () => setShow(false)

  const onSubmit = async vals => {
    const cardData = { cardNumber: "", month: "", year: "", cardCode: "" }
    const { card, expDate, cvv } = vals
    const { year, month } = formatDateForDB(expDate)
    cardData.cardNumber = card
    cardData.year = year
    cardData.month = month
    cardData.cardCode = cvv
    setIsLoading(true)
    confirmPayment(cardData)
      .then(response => {
        const { messages, opaqueData } = response
        if (messages.resultCode === "Ok") {
          addPaymentProfile(opaqueData)
            .then(() => {
              getLoginUserProfile()
                .then(user => {
                  localStorage.setItem("user", JSON.stringify(user))
                  nav("/orders")
                })
                .catch(() => {
                  setIsLoading(false)
                  nav("/orders")
                })
              // .finally(() => nav("/dashboard"))

              return
              const { state } = location
              if (state?.subscriptionId) {
                const payload = { tier: state.subscriptionId }
                addSubscriptionRequest(payload)
                  .then(response => {
                    const { is_subscribed } = response
                    if (!is_subscribed) {
                      return nav("/subscription")
                    }
                    notifySuccess("You subscribed successfully.")
                    setTimeout(() => nav("/dashboard"), 300)
                  })
                  .catch(() => setIsLoading(false))
              }
            })
            .catch(() => setIsLoading(false))
        }
      })
      .catch(() => setIsLoading(false))
  }

  return (
    <AuthContainer
      title="Add a Card"
      subTitle="Please enter your car details to fulfil payments"
      icon={
        <img
          src={assets.BackArrowIcon}
          style={{ cursor: "pointer" }}
          onClick={() => nav("/subscription")}
        />
      }
      className="payment"
    >
      <div className="signup-form">
        <PaymentForm isLoading={loading} onSubmit={onSubmit} />
      </div>
      <CustomModal
        size="md"
        headerTitle="Warning"
        showHeader={true}
        footerBtnText="Ok"
        showFooter={true}
        show={show}
        onHide={onHide}
      >
        <p>
          I understand I need a 3rd party payment processor such as
          Authorize.net. Items sold on this application cannot be processed via
          Shopify Pay.
        </p>
      </CustomModal>
    </AuthContainer>
  )
}
