import React from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useAuthContext } from "../contexts/AuthContext"

export function PrivateRoute({ children }) {
  const { authToken } = useAuthContext()
  console.log("PrivateRoute authToken", authToken)
  const { pathname } = useLocation()
  if (authToken) return children

  return <Navigate to="/login" state={{ from: pathname }} replace={true} />
}
