import React, { useEffect } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { AuthContainer } from "../../common/AuthContainer"
import { InputField } from "../../components/InputField"
import { useForm } from "react-hook-form"
import { formData } from "./formData"
import { Col, Row } from "react-bootstrap"
import { Checkbox } from "../../components/Checkbox"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAuthContext } from "../../contexts/AuthContext"
import { InputFieldPassword } from "../../components/InputFieldPassword"
import { CustomButton } from "../../components/CustomButton"
import { CustomSpinner } from "../../components/CustomSpinner"

export const Signup = () => {
  const nav = useNavigate()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const { isLoading, notifySuccess, userSignup, signWithShopify } =
    useAuthContext()

  const formSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    name: Yup.string()
      .required("Name is required")
      .min(3, "Name must be at 3 char long")
      .trim()
      .matches(/^[a-zA-Z ]+$/, "Invalid name"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at 6 char long")
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Password must be at least 8 letters, one capital & small letter, one digit and at least one special symbol from [ ! @ # $ % ^ & * ]"
      ),
    cpassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
    termsOfService: Yup.boolean()
      .required("The terms & conditions and privacy policy must be accepted.")
      .oneOf(
        [true],
        "The terms & conditions and privacy policy must be accepted."
      )
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { handleSubmit, control, trigger } = useForm(formOptions)

  const onSubmit = vals => {
    const { email, name, password } = vals
    userSignup({ email, name, password }).then(authFlow)
  }

  useEffect(() => {
    if (searchParams.size) {
      const config = { params: {} }
      for (const [key, value] of searchParams.entries()) {
        config.params[key] = value
      }
      signWithShopify(config).then(authFlow)
    }
  }, [])

  const authFlow = user => {
    const { user_store, is_subscribed } = user
    if (!user_store?.id) {
      setTimeout(() => nav("/link-shopify-app"), 300)
    } else if (!is_subscribed) {
      setTimeout(() => nav("/subscription"), 300)
    }
    if (user_store?.id && is_subscribed) {
      notifySuccess("You have logged in successfully!")
      setTimeout(() => nav("/dashboard"), 300)
    }
  }

  const renderFormData = () => {
    return formData.map(
      ({ label, name, type, placeholder, rules, controlType }, index) => {
        if (controlType === "check") {
          return (
            <Checkbox
              key={index}
              label={label}
              name={name}
              type={type}
              control={control}
              rules={rules}
            />
          )
        } else if (type === "password") {
          return (
            <InputFieldPassword
              key={index}
              label={label}
              name={name}
              type={type}
              placeholder={placeholder}
              control={control}
              trigger={trigger}
              rules={rules}
            />
          )
        }
        return (
          <InputField
            key={index}
            label={label}
            name={name}
            type={type}
            placeholder={placeholder}
            control={control}
            trigger={trigger}
            rules={rules}
          />
        )
      }
    )
  }

  return (
    <>
      {searchParams.size > 0 ? (
        <AuthContainer className="shopifyLogin">
          <CustomSpinner />
        </AuthContainer>
      ) : (
        <AuthContainer title="Sign Up" className="signup">
          <div className="signup-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              {renderFormData()}
              <Row className="justify-content-center">
                <Col xs="11" className="mb-3">
                  <CustomButton
                    label="Sign Up"
                    variant="primary"
                    type="submit"
                    loading={isLoading}
                  />
                </Col>

                <Col className="text-center">
                  <p>
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </Col>
              </Row>
            </form>
          </div>
        </AuthContainer>
      )}
    </>
  )
}
