import React, { createContext, useContext, useEffect, useState } from "react"
import { useMyProductsContext } from "./MyProductsContext"
import { API } from "../api"

const DashboardContext = createContext()

const DashboardProvider = ({ children }) => {
  const { filterParams, setFilterParams, getBrands, getProductTypes } =
    useMyProductsContext()
  const [isLoading, setIsLoading] = useState(false)
  const [summary, setSummary] = useState({})
  const [bestsellersActive, setBestSellersActive] = useState(false)
  const [bestsellers, setBestSellers] = useState([])
  const [suggested, setSuggested] = useState([])
  const [prieUpdatesActive, setPrieUpdatesActive] = useState(false)
  const [analytics, setAnalytics] = useState({
    monthly_data: [],
    total_order: 0,
    total_profit: 0,
    total_sales: 0
  })

  const config = { params: { bestseller: true, page: 1 } }

  const getSummary = () => {
    setIsLoading(true)
    API.getSummary()
      .then(response => {
        setSummary(response.data.data)
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getBestSellers = () => {
    setIsLoading(true)
    API.getBrowseProducts(config).then(response =>
      setBestSellers(response.data.data.results)
    )
  }

  const getSuggested = () => {
    setIsLoading(true)
    API.getBrowseProducts({ params: { suggested: true, page: 1 } }).then(
      response => setSuggested(response.data.data.results)
    )
  }

  const resetPriceUpdates = () => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.resetPriceUpdates()
        .then(() => {
          resolve("Ok")
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const changeItem = item => {
    const monthMapping = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec"
    }
    const newItem = { ...item }
    newItem.date = `${monthMapping[item.month]} ${item.year
      .toString()
      ?.slice(-2)}`
    return newItem
  }

  const getOrderAnalytics = () => {
    setIsLoading(true)
    API.getOrderAnalytics().then(response => {
      const { monthly_data, total_order, total_profit, total_sales } =
        response.data.data
      setAnalytics({
        monthly_data: monthly_data.map(changeItem),
        total_order,
        total_profit,
        total_sales
      })
    })
  }

  // useEffect(() => {
  //   if (bestsellersActive) {
  //     delete filterParams.my_products
  //     delete filterParams.price_updates
  //     filterParams.bestseller = true
  //     getBrands({ params: { bestseller: true } })
  //     getProductTypes({ params: { bestseller: true } })
  //   } else if (prieUpdatesActive) {
  //     delete filterParams.my_products
  //     delete filterParams.bestseller
  //     filterParams.price_updates = true
  //     getBrands({ params: { price_updates: true } })
  //     getProductTypes({ params: { price_updates: true } })
  //   } else {
  //     delete filterParams.bestseller
  //     delete filterParams.price_updates
  //     filterParams.my_products = true
  //     getBrands({ params: { my_products: true } })
  //     getProductTypes({ params: { my_products: true } })
  //   }
  //   setFilterParams({ page: 1, ...filterParams, vendor: [], product_type: [] })
  // }, [bestsellersActive, prieUpdatesActive])

  return (
    <DashboardContext.Provider
      value={{
        isLoading,
        summary,
        getSummary,
        bestsellersActive,
        setBestSellersActive,
        bestsellers,
        getBestSellers,
        suggested,
        getSuggested,
        prieUpdatesActive,
        setPrieUpdatesActive,
        resetPriceUpdates,
        analytics,
        getOrderAnalytics
      }}
    >
      {children}
    </DashboardContext.Provider>
  )
}

export const useDashboardContext = () => useContext(DashboardContext)

export default DashboardProvider
