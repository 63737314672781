import React from "react"
import { useForm } from "react-hook-form"
import { CustomCard } from "../../../components/CustomCard"
import { Col, Row, Form } from "react-bootstrap"
import { InputField } from "../../../components/InputField"

export const StoreDetails = ({ url }) => {
  const { control, trigger } = useForm()

  return (
    <CustomCard title="Store Details">
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <InputField
              label="URL"
              name="url"
              value={url}
              control={control}
              trigger={trigger}
              disabled={true}
            />
          </Form.Group>
        </Col>
      </Row>
    </CustomCard>
  )
}
