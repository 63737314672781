import React, { useEffect, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { CustomModal } from "../../components/CustomModal"
import { useForm } from "react-hook-form"
import { Col, Row } from "react-bootstrap"
import { CustomSelect } from "../../components/CustomSelect"
import { CustomButton } from "../../components/CustomButton"
import { useOrdersContext } from "../../contexts/OrdersContext"
import { CustomSpinner } from "../../components/CustomSpinner"

export const ShippingDetails = ({ show, onHide, orderId, onSubmit }) => {
  const { isLoading, carriers, getCarriers, rates, getRates } =
    useOrdersContext()
  const [carrier, setCarrier] = useState(null)
  const [code, setCode] = useState(null)

  const formSchema = Yup.object().shape({
    shipping_carrier: Yup.string().required("Carrier is required"),
    shipping_code: Yup.string().required("Delivery Type is required")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { handleSubmit, control, setValue, clearErrors, trigger } =
    useForm(formOptions)

  useEffect(() => {
    if (carrier && orderId) {
      clearErrors("shipping_carrier")
      getRates(orderId, { params: { carrier } })
    }
  }, [carrier])

  useEffect(() => {
    if (code) {
      clearErrors("shipping_code")
    }
  }, [code])

  useEffect(() => {
    getCarriers()
  }, [])

  return (
    <CustomModal
      size="md"
      headerTitle="Shipping"
      showHeader={true}
      show={show}
      onHide={onHide}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="justify-content-center">
          <Col xs="12" lg="10">
            <CustomSelect
              label="Carrier"
              name="shipping_carrier"
              options={carriers}
              placeholder={"Please select a carrier"}
              onChange={option => {
                setValue("shipping_carrier", option.value)
                setCarrier(option.value)
              }}
              isDisabled={isLoading}
              control={control}
              trigger={trigger}
            />
          </Col>
          <Col xs="12" lg="10">
            <CustomSelect
              label="Delivery Type (Rate in $)"
              name="shipping_code"
              options={rates}
              placeholder={"Please select a mode"}
              onChange={option => {
                setValue("shipping_code", option.value)
                setCode(option.value)
              }}
              isDisabled={isLoading}
              control={control}
              trigger={trigger}
            />
          </Col>
        </Row>
        <Row className="flex-row justify-content-center">
          <Col xs="6" lg="4">
            <CustomButton
              label="Cancel"
              type="button"
              variant="outline-primary"
              onClick={onHide}
            />
          </Col>
          <Col xs="6" lg="4">
            <CustomButton label="Confirm" disabled={isLoading} />
          </Col>
        </Row>
      </form>
      <CustomSpinner loading={isLoading} />
    </CustomModal>
  )
}
