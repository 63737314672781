import React, { useEffect, useState } from "react"
import { Chip } from "../../components/Chip"

export const Variant = ({
  id,
  name,
  values = [],
  optionSelected,
  setSelectedVariant
}) => {
  const [options, setOptions] = useState([])

  const onClickHandler = label => {
    const update = options.map(item => ({ ...item, checked: false }))
    const index = update.findIndex(item => item.label === label)
    update[index].checked = true
    setOptions(update)
    setSelectedVariant(prevState => ({ ...prevState, [name]: label }))
  }

  useEffect(() => {
    setOptions(
      values.map(value => ({
        label: value,
        value: id,
        checked: value == optionSelected ? true : false
      }))
    )
    setSelectedVariant(prevState => ({ ...prevState, [name]: optionSelected }))

  }, [optionSelected])

  return options.map(({ label, value, checked }) => (
    <Chip
      label={label}
      className={`${checked ? "active" : ""}`}
      onClick={() => onClickHandler(label)}
    />
  ))
}
