import { CustomCard } from "../../components/CustomCard"
import { Row, Col } from "react-bootstrap"
import { CustomButton } from "../../components/CustomButton"
import { assets } from "../../utils"
import "./ordersdetails.scss"

export const PaymentCard = ({
  status,
  total_cost_price,
  total_amount_paid,
  service_fee,
  shipping_fee,
  onClick,
  shippingMode,
  onClickSelectMode
}) => {
  return (
    <CustomCard title="Payment" className="payment-info">
      <Row>
        <Col>
          <p>Sub Total</p>
        </Col>
        <Col>
          <p>{`$ ${
            Number(total_cost_price).toFixed(2) || Number(0).toFixed(2)
          }`}</p>
        </Col>
        <hr />
        <Col>
          <p>Service Fee</p>
        </Col>
        <Col>
          <p>{`$ ${Number(service_fee).toFixed(2) || Number(0).toFixed(2)}`}</p>
        </Col>
        <hr />
        <Col>
          <p>Shipping</p>
        </Col>
        <Col className="d-flex">
          {status === "Unpaid" ? (
            shippingMode ? (
              <div className="d-flex align-items-start">
                <p style={{ whiteSpace: "nowrap" }}>{`$ ${Number(
                  shipping_fee
                ).toFixed(2)}`}</p>
                <img
                  src={assets.EditIcon}
                  className="ms-3"
                  style={{
                    width: "1rem",
                    height: "auto",
                    cursor: "pointer"
                  }}
                  onClick={onClickSelectMode}
                />
              </div>
            ) : (
              <p
                onClick={onClickSelectMode}
                style={{ color: "#27963C", cursor: "pointer" }}
                className={status !== "Unpaid" ? "ms-3" : ""}
              >
                Select Mode
              </p>
            )
          ) : (
            <p>{`$ ${Number(shipping_fee).toFixed(2)}`}</p>
          )}
        </Col>
        <hr />
        <Col>
          <p>TOTAL</p>
        </Col>
        <Col>
          <p>{`$ ${
            Number(total_amount_paid).toFixed(2) || Number(0).toFixed(2)
          }`}</p>
        </Col>
        <hr />
      </Row>
      <Row className="justify-content-end mt-1">
        <Col xs="3">
          <CustomButton
            label="Pay"
            type="button"
            variant="primary"
            disabled={!shippingMode}
            className={`${status === "Unpaid" ? "visible" : "invisible"}`}
            onClick={onClick}
          />
        </Col>
      </Row>
    </CustomCard>
  )
}
