import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../api"
import {
  loadFromLocalStorage,
  saveToLocalStorage
} from "../utils/localStorageHelper"

const BrowseProductsContext = createContext()

const BrowseProductsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [browseProducts, setBrowseProducts] = useState({
    count: 0,
    next: null,
    results: []
  })
  const [product, setProduct] = useState({})
  const [brands, setBrands] = useState([])
  const [productTypes, setProductTypes] = useState([])
  const [suggestedActive, setSuggestedActive] = useState(false)

  const [filterParams, setFilterParams] = useState({
    vendor: [],
    product_type: [],
    inStock: true
  })

  const getBrowseProducts = config => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getBrowseProducts(config)
        .then(response => {
          const { count, next, results } = response.data.data
          if (config.params.page == 1) {
            setBrowseProducts(response.data.data)
          } else {
            setBrowseProducts({
              next: next,
              results: [...browseProducts.results, ...results]
            })
          }
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getBrowseProduct = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getBrowseProduct(id)
        .then(response => {
          setProduct(response.data.data)
          resolve("Ok")
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getBrands = config => {
    setIsLoading(true)
    API.getBrands(config)
      .then(response => {
        setBrands(response.data.data.map(item => ({ ...item, checked: false })))
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const getProductTypes = config => {
    setIsLoading(true)
    API.getProductTypes(config)
      .then(response => {
        setProductTypes(
          response.data.data.map(item => ({ ...item, checked: false }))
        )
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const addToMyProducts = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addToMyProducts(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addProductToMyStore = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      addToMyProducts(payload)
        .then(response => {
          const productIds = {}
          payload.forEach(({ product }) => {
            productIds[product] = product
          })
          if (browseProducts.results.length) {
            const update = browseProducts.results.map(item => {
              if (productIds[item.id]) {
                return { ...item, if_present_in_user_store: true }
              } else {
                return item
              }
            })
            setBrowseProducts(prevState => ({ ...prevState, results: update }))
          }
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const removeProductFromMyStore = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteMyProduct(id)
        .then(response => {
          const product = id
          const update = [...browseProducts.results]
          if (update.length) {
            const index = update.findIndex(item => item.id === product)
            update[index].if_present_in_user_store = false
            setBrowseProducts(prevState => ({ ...prevState, results: update }))
          }
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const updateSellingPrice = (id, payload) => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.updateSellingPrice(id, payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const restoreGlobalState = prevState => {
    const { brands, productTypes } = prevState
    setBrands(brands)
    setProductTypes(productTypes)
  }

  // useEffect(() => {
  //   if (suggestedActive) {
  //     filterParams.suggested = true
  //     getBrands({ params: { suggested: true } })
  //     getProductTypes({ params: { suggested: true } })
  //   } else {
  //     delete filterParams.suggested
  //     getBrands()
  //     getProductTypes()
  //   }
  //   setFilterParams({ page: 1, ...filterParams, vendor: [], product_type: [] })
  // }, [suggestedActive])

  useEffect(() => {
    window.onbeforeunload = () => {
      saveToLocalStorage("filters", {
        brands,
        productTypes
      })
    }

    const prevState = loadFromLocalStorage("filters")
    if (!prevState) {
      saveToLocalStorage("filters", {
        brands,
        productTypes
      })
    }

    if (prevState) {
      restoreGlobalState(prevState)
    }
    return () => {
      window.onbeforeunload = null
      saveToLocalStorage("filters")
    }
  }, [brands, productTypes])

  return (
    <BrowseProductsContext.Provider
      value={{
        isLoading,
        browseProducts,
        getBrowseProducts,
        product,
        getBrowseProduct,
        setProduct,
        brands,
        getBrands,
        productTypes,
        getProductTypes,
        filterParams,
        setFilterParams,
        setBrands,
        setProductTypes,
        addToMyProducts,
        removeProductFromMyStore,
        addProductToMyStore,
        updateSellingPrice,
        suggestedActive,
        setSuggestedActive
      }}
    >
      {children}
    </BrowseProductsContext.Provider>
  )
}

export const useBrowseProductsContext = () => useContext(BrowseProductsContext)

export default BrowseProductsProvider
