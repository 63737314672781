import React from "react"
import { Col, Row } from "react-bootstrap"
import { CustomCard } from "../../../components/CustomCard"
import { CustomButton } from "../../../components/CustomButton"
import { assets } from "../../../utils"
import { useNavigate } from "react-router-dom"

export const ProductAlert = ({
  title,
  out_of_stock,
  price_updates,
  setFilterParams,
  setPrieUpdatesActive
}) => {
  const nav = useNavigate()
  return (
    <CustomCard
      title={title}
      subTitle={
        <Row className="flex-column gap-3">
          <Col>
            <Row>
              <Col xs="12">
                <h6>Out of Stock</h6>
              </Col>
              <Col xs="12" md="12" lg="6">
                <div className="d-flex align-items-center item w-75">
                  <h1
                    style={{
                      fontWeight: "700",
                      fontSize: "40px",
                      lineHeight: "18px"
                    }}
                  >
                    {out_of_stock || 0}
                  </h1>
                  <p className="mb-1 ms-2">
                    Products from your <br />
                    listing are out of stock
                  </p>
                </div>
              </Col>
              <Col xs="12" md="12" lg="6">
                <div className="ms-auto">
                  <CustomButton
                    label="View All"
                    backgroundColor="white"
                    onClick={() => {
                      setFilterParams(prevFilters => ({
                        ...prevFilters,
                        my_products: true,
                        inStock: false,
                        outOfStock: true
                      }))
                      nav(`/my-products`)
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs="12">
                <h6>Price Updates</h6>
              </Col>
              <Col xs="12" md="12" lg="6">
                <div className="d-flex align-items-center item w-75">
                  <h1
                    style={{
                      fontWeight: "700",
                      fontSize: "40px",
                      lineHeight: "18px"
                    }}
                  >
                    {price_updates || 0}
                  </h1>
                  <p className="mb-1 ms-2">
                    Products from your <br />
                    listing have price updates
                  </p>
                </div>
              </Col>
              <Col xs="12" md="12" lg="6">
                <div className="ms-auto">
                  <CustomButton
                    label="View All"
                    backgroundColor="white"
                    onClick={() => {
                      setPrieUpdatesActive(true)
                      nav(`/my-products`)
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      }
      src={assets.ProductAlertIcon}
      className="product-alert"
    ></CustomCard>
  )
}
