import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import AppRoutes from "./routes"
import AuthProvider from "./contexts/AuthContext"
import { ErrorAlert } from "./components/ErrorAlert"
import UserProfileProvider from "./contexts/UserProfileContext"
import BrowseProductsProvider from "./contexts/BrowseProductsContext"
import MyProductsProvider from "./contexts/MyProductsContext"
import DashboardProvider from "./contexts/DashboardContext"
import OrdersProvider from "./contexts/OrdersContext"

function App() {
  return (
    <AuthProvider>
      <UserProfileProvider>
        <BrowseProductsProvider>
          <MyProductsProvider>
            <DashboardProvider>
              <OrdersProvider>
                <Router>
                  <AppRoutes />
                  <ErrorAlert />
                </Router>
              </OrdersProvider>
            </DashboardProvider>
          </MyProductsProvider>
        </BrowseProductsProvider>
      </UserProfileProvider>
    </AuthProvider>
  )
}
export default App
