import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { Row, Col, Tab, Tabs } from "react-bootstrap"
import { CustomButton } from "../../../components/CustomButton"
import { CustomContainer } from "../../../components/CustomContainer"
import { CustomCard } from "../../../components/CustomCard"
import { assets, convertTZ } from "../../../utils"
import { CustomAgGridTable } from "../../../components/CustomAgGridTable"
import { useOrdersContext } from "../../../contexts/OrdersContext"
import { CustomSpinner } from "../../../components/CustomSpinner"
import { useDashboardContext } from "../../../contexts/DashboardContext"
import { useAuthContext } from "../../../contexts/AuthContext"
import { useUserProfileContext } from "../../../contexts/UserProfileContext"
import "./order.scss"

export function Orders() {
  const user = JSON.parse(localStorage.getItem("user"))
  const timezone = user.user_profile.timezone
  const nav = useNavigate()
  const location = useLocation()
  const [key, setKey] = useState("")
  const { summary, getSummary } = useDashboardContext()
  const { isLoading, orders, getOrders, syncOrders, downloadInvoice } =
    useOrdersContext()
  const { notifySuccess } = useAuthContext()
  const { userProfile, getLoginUserProfile } = useUserProfileContext()

  const {
    open_orders,
    out_of_stock,
    payment_pending,
    payment_pending_amount,
    price_updates,
    total_products
  } = summary

  const [timeInterval, setTimeInterval] = useState(null)

  const onRowClicked = row => {
    const { is_payment_profile_exists } = userProfile
    if (!is_payment_profile_exists) {
      return nav(`/payment`)
    }

    const { defaultPrevented } = row.event
    if (!defaultPrevented) {
      nav(`/orders/${row.data.id}`)
    }
  }

  const loadOrders = () => {
    const config = { params: { common_status: "" } }
    if (key && key !== "all") {
      config.params.common_status = key
    }
    getOrders(config)
  }

  useEffect(() => {
    getSummary()
    getLoginUserProfile()
  }, [])

  useEffect(() => {
    loadOrders()
  }, [key])

  useEffect(() => {
    if (location.state?.common_status) {
      setKey(location.state?.common_status)
    } else {
      setKey("all")
    }
  }, [location.state?.common_status])

  useEffect(() => {
    if (timeInterval) {
      setTimeout(function () {
        clearInterval(timeInterval)
      }, 5 * 60 * 1000)
    }
  }, [timeInterval])

  const columns = [
    {
      field: "name",
      headerName: "Order No.",
      flex: 1,
      minWidth: 150
    },
    {
      field: "order_created_at",
      headerName: "Order Date",
      flex: 1,
      minWidth: 150,
      cellRenderer: ({ value }) => <span>{convertTZ(value, timezone)}</span>
    },
    {
      field: "customer_name",
      headerName: "Customer",
      flex: 1,
      minWidth: 150
    },
    {
      field: "total_cost_price",
      headerName: "Total Value",
      flex: 1,
      minWidth: 150,
      cellRenderer: ({ value }) => (
        <span>{`$ ${Number(value).toFixed(2)}`}</span>
      )
    },
    {
      field: "common_status_descr",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      cellRenderer: ({ value }) => <span className={value}>{value}</span>
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      minWidth: 200,
      cellRenderer: ({ data: { id, common_status } }) => {
        if (common_status === "U") {
          return (
            <CustomButton label="Pay" variant="primary" className="ms-auto" />
          )
        }
        if (common_status === "C") {
          return (
            null
          )
        }
        return (
          <CustomButton
            label="Download Invoice"
            variant="outline-primary"
            className="ms-auto"
            type="button"
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              downloadInvoice(id)
            }}
          />
        )
      }
    }
  ]

  return (
    <CustomContainer title="">
      <Row>
        <Col xs="8" lg="11" xl="10">
          <h3 className="header-title mb-3">Orders</h3>
        </Col>
        <Col xs="4" lg="1" xl="2">
          <CustomButton
            label="Sync Orders"
            variant="outline-primary"
            backgroundColor="white"
            className="ms-auto"
            disabled={isLoading}
            onClick={() =>
              syncOrders().then(() => {
                notifySuccess("Orders will be synced in sometime.")
                const timeInterval = setInterval(
                  () => Promise.allSettled([loadOrders(), getSummary()]),
                  5000
                )
                setTimeInterval(timeInterval)
              })
            }
          />
        </Col>
      </Row>
      <Row className="card-row orders">
        <Col lg="4">
          <CustomCard
            title={`Open orders: ${open_orders || 0}`}
            src={assets.OpenOrdersIcon}
            className="orders"
            onClick={() => setKey("all")}
            style={{ cursor: "pointer" }}
          />
        </Col>
        <Col lg="4">
          <CustomCard
            title={`Payment Pending: ${payment_pending || 0}`}
            src={assets.DollarIcon}
            className="orders"
            onClick={() => setKey("U")}
            style={{ cursor: "pointer" }}
          />
        </Col>
        {/* <Col lg="4">
          <CustomCard
            title={"Alerts: 01"}
            src={assets.ProductAlertIcon}
            className="orders"
          />
        </Col> */}
      </Row>
      <Row className="orders-tabs">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={k => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="all" title="All">
            <CustomAgGridTable
              key="all"
              rowData={orders.results}
              columnDefs={columns}
              onRowClicked={onRowClicked}
              pagination={true}
            />
          </Tab>
          <Tab eventKey="O" title="Open">
            <CustomAgGridTable
              key="Open"
              rowData={orders.results}
              columnDefs={columns}
              onRowClicked={onRowClicked}
              pagination={true}
            />
          </Tab>
          <Tab eventKey="U" title="Unpaid">
            <CustomAgGridTable
              key="Unpaid"
              rowData={orders.results}
              columnDefs={columns}
              onRowClicked={onRowClicked}
              pagination={true}
            />
          </Tab>
          <Tab eventKey="PS" title="Partially">
            <CustomAgGridTable
              key="Partially"
              rowData={orders.results}
              columnDefs={columns}
              onRowClicked={onRowClicked}
              pagination={true}
            />
          </Tab>
          <Tab eventKey="S" title="Fullfilled">
            <CustomAgGridTable
              key="Fullfilled"
              rowData={orders.results}
              columnDefs={columns}
              onRowClicked={onRowClicked}
              pagination={true}
            />
          </Tab>
          <Tab eventKey="R" title="Returned">
            <CustomAgGridTable
              key="Returned"
              rowData={orders.results}
              columnDefs={columns}
              onRowClicked={onRowClicked}
              pagination={true}
            />
          </Tab>
          <Tab eventKey="C" title="Cancelled">
            <CustomAgGridTable
              key="Cancelled"
              rowData={orders.results}
              columnDefs={columns}
              onRowClicked={onRowClicked}
              pagination={true}
            />
          </Tab>
        </Tabs>
      </Row>
      <CustomSpinner loading={isLoading} />
    </CustomContainer>
  )
}
