import React from "react"
import { Form } from "react-bootstrap"
import { Controller } from "react-hook-form"

export const Checkbox = ({
  label,
  name,
  type,
  control,
  trigger,
  rules,
  defaultChecked,
  disabled,
  ...moreProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, ref },
        fieldState: { invalid },
        formState: { errors }
      }) => {
        return (
          <Form.Group
            className="is-invalid mb-3"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Check
              label={label}
              name={name}
              type={type}
              defaultChecked={defaultChecked}
              disabled={disabled}
              onChange={async value => {
                onChange(value)
                trigger(name)
              }}
              onBlur={onBlur}
              ref={ref}
              isInvalid={invalid}
              className={`${
                errors[name] ? "is-invalid" : ""
              } d-flex align-items-center`}
              id="validationFormik0"
              {...moreProps}
            />
            {errors[name]?.message && (
              <Form.Control.Feedback type="invalid">
                {errors[name]?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        )
      }}
    />
  )
}
