export const formData = [
  {
    label: "Name",
    name: "name",
    type: "text",
    placeholder: "Name",
    colSize: 12
  },
  {
    label: "Address Line 1",
    name: "address_line1",
    type: "text",
    placeholder: "Address line 1",
    colSize: 12
  },
  {
    label: "Address Line 2 (Optional)",
    name: "address_line2",
    type: "text",
    placeholder: "Address line 2",
    colSize: 12
  },

  {
    label: "City",
    name: "city",
    type: "text",
    placeholder: "City",
    colSize: 6
  },

  {
    label: "Zip Code",
    name: "zipcode",
    type: "text",
    placeholder: "Zip Code",
    colSize: 6
  },

  {
    label: "State",
    name: "state",
    type: "text",
    placeholder: "State",
    colSize: 6
  },

  {
    label: "Country",
    name: "country",
    type: "text",
    placeholder: "Country",
    colSize: 6
  },
  {
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Please enter a valid email",
    rules: { required: "Email is required!" }
  }
]
