import React from "react"
import { Col, Row } from "react-bootstrap"
import Card from "react-bootstrap/Card"
import "./CustomCard.scss"

export const CustomCard = ({
  title,
  subTitle,
  src,
  icon,
  className,
  children,
  onClick,
  ...rest
}) => {
  return (
    <Card
      className={`${className}-card custom-card`}
      {...rest}
      onClick={onClick}
    >
      {src && (
        <Card.Img
          loading="lazy"
          variant="top"
          src={src}
          // className="d-none d-lg-block"
        />
      )}

      <Card.Body>
        {icon ? (
          <Row>
            <Col xs={12} className="d-flex align-items-baseline">
              {icon}
              <Card.Title className={`${className} text-nowrap mb-3`}>
                {title}
              </Card.Title>
            </Col>
          </Row>
        ) : (
          title && (
            <Card.Title className={`${className} text-nowrap mb-3`}>
              {title}
            </Card.Title>
          )
        )}
        {subTitle && (
          <Card.Text className={`${className}`}>{subTitle}</Card.Text>
        )}
        {children}
      </Card.Body>
    </Card>
  )
}
