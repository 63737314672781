import React from "react"
import { Variant } from "./variant"

export const Variants = ({
  product_catalog_options,
  catalogVariantSelected,
  setSelectedVariant
}) => {
  return (
    <div style={{ overflow: "hidden" }}>
      {product_catalog_options.map(({ id, name, values }, index) => (
        <div
          className={`${
            values?.length === 1 && values?.includes("Default Title")
              ? "invisible"
              : ""
          }`}
        >
          <div className="variant-name">
            <h6 className="title text-muted" style={{ fontSize: "0.75rem" }}>
              {name}
            </h6>
          </div>
          <div className="d-flex gap-2 mb-3" style={{ overflowX: "auto" }}>
            <Variant
              key={id}
              id={id}
              name={name}
              values={values}
              optionSelected={catalogVariantSelected[`option${index + 1}`]}
              setSelectedVariant={setSelectedVariant}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
