import React, { createContext, useContext, useEffect, useState } from "react"
import { API } from "../api"
import {
  loadFromLocalStorage,
  saveToLocalStorage
} from "../utils/localStorageHelper"

const MyProductsContext = createContext()

const MyProductsProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [browseProducts, setBrowseProducts] = useState({
    count: 0,
    next: null,
    results: []
  })
  const [product, setProduct] = useState({})
  const [brands, setBrands] = useState([])
  const [productTypes, setProductTypes] = useState([])

  const [filterParams, setFilterParams] = useState({
    vendor: [],
    product_type: [],
    inStock: true
  })

  const [showResetPriceUpdates, setShowResetPriceUpdates] = useState(false)

  const getBrowseProducts = config => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getBrowseProducts(config)
        .then(response => {
          const { count, next, results } = response.data.data
          if (config.params.page == 1) {
            setBrowseProducts(response.data.data)
          } else {
            setBrowseProducts({
              next: next,
              results: [...browseProducts.results, ...results]
            })
          }
          if (filterParams.price_updates && results.length > 0) {
            setShowResetPriceUpdates(true)
          }
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getBrowseProduct = id => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.getBrowseProduct(id)
        .then(response => {
          setProduct(response.data.data)
          resolve("Ok")
        })
        .catch(error => console.log(error))
        .finally(() => setIsLoading(false))
    })
  }

  const getBrands = config => {
    setIsLoading(true)
    API.getBrands(config)
      .then(response => {
        setBrands(response.data.data.map(item => ({ ...item, checked: false })))
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const updateInStock = (data) => {
    setIsLoading(true)
    return new Promise(resolve => {
      API.updateStock(data)
      .then(response => {
        resolve("Ok")
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
    })
    
  }
  const getProductTypes = config => {
    setIsLoading(true)
    API.getProductTypes(config)
      .then(response => {
        setProductTypes(
          response.data.data.map(item => ({ ...item, checked: false }))
        )
      })
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

  const addToMyProducts = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addToMyProducts(payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addProductToMyStore = vals => {
    const { my_store_product_variants, product } = vals
    const payload = {}
    payload.product = product
    payload.my_store_product_variants = my_store_product_variants.map(
      ({ id, price }) => ({
        variant: id,
        price
      })
    )

    setIsLoading(true)
    return new Promise((resolve, reject) => {
      addToMyProducts(payload)
        .then(response => {
          const { product } = response.data.data
          const update = [...browseProducts.results]
          if (update.length) {
            const index = update.findIndex(item => item.id === product)
            update[index].if_present_in_user_store = true
            setBrowseProducts(prevState => ({ ...prevState, results: update }))
          }
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const removeProductFromMyStore = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.deleteMyProduct(id)
        .then(response => {
          const product = id
          const update = browseProducts.results.filter(
            ({ id }) => id !== product
          )
          setBrowseProducts(prevState => ({ ...prevState, results: update }))

          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const bulkDeleteMyProducts = selectedProducts => {
    const config = { params: { id: "" } }
    config.params.id = selectedProducts.map(product => product).join(",")
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.bulkDelete(config)
        .then(response => {
          const idsObj = {}
          selectedProducts.forEach(id => {
            idsObj[id] = id
          })
          const update = browseProducts.results.filter(
            ({ id }) => id !== idsObj[id]
          )
          setBrowseProducts(prevState => ({ ...prevState, results: update }))
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const addVariant = payload => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.addVariant(payload)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const removeVariant = id => {
    setIsLoading(true)
    return new Promise((resolve, reject) => {
      API.removeVariant(id)
        .then(response => {
          resolve(response.data.data)
        })
        .catch(error => reject(error))
        .finally(() => setIsLoading(false))
    })
  }

  const restoreGlobalState = prevState => {
    const { brands, productTypes } = prevState
    setBrands(brands)
    setProductTypes(productTypes)
  }

  useEffect(() => {
    window.onbeforeunload = () => {
      saveToLocalStorage("filters", {
        brands,
        productTypes
      })
    }

    const prevState = loadFromLocalStorage("filters")
    if (!prevState) {
      saveToLocalStorage("filters", {
        brands,
        productTypes
      })
    }

    if (prevState) {
      restoreGlobalState(prevState)
    }
    return () => {
      window.onbeforeunload = null
      saveToLocalStorage("filters")
    }
  }, [brands, productTypes])

  return (
    <MyProductsContext.Provider
      value={{
        isLoading,
        browseProducts,
        getBrowseProducts,
        product,
        getBrowseProduct,
        brands,
        getBrands,
        productTypes,
        getProductTypes,
        filterParams,
        setFilterParams,
        setBrands,
        setProductTypes,
        addToMyProducts,
        removeProductFromMyStore,
        addProductToMyStore,
        bulkDeleteMyProducts,
        showResetPriceUpdates,
        setShowResetPriceUpdates,
        addVariant,
        removeVariant,
        updateInStock
      }}
    >
      {children}
    </MyProductsContext.Provider>
  )
}

export const useMyProductsContext = () => useContext(MyProductsContext)

export default MyProductsProvider
