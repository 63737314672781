import React, { useEffect, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { CustomCard } from "../../../components/CustomCard"
import { useForm } from "react-hook-form"
import { InputField } from "../../../components/InputField"
import { formData } from "./formData"
import { Col, Row } from "react-bootstrap"
import { assets } from "../../../utils"
import { useUserProfileContext } from "../../../contexts/UserProfileContext"
import { CustomButton } from "../../../components/CustomButton"

export const ContactInfo = () => {
  const { isLoading, userProfile, updateUserProfile, getLoginUserProfile } =
    useUserProfileContext()
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .min(3, "Name must be at 3 char long")
      .trim()
      .matches(/^[a-zA-Z ]+$/, "Invalid name"),
    address_line1: Yup.string().required("Address line 1 is required"),
    // address_line2: Yup.string().required("Address line 2 is required"),
    city: Yup.string().required("City is required"),
    zipcode: Yup.string().required("Zip code is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { control, trigger, setValue, handleSubmit } = useForm(formOptions)
  const [data, setData] = useState([])
  const [editMode, setEditMode] = useState(false)
  const [values, setValues] = useState({})

  const onSubmit = vals => {
    const id = userProfile?.user_profile?.id
    delete vals.email
    delete vals.photo
    updateUserProfile(id, vals).then(() => {
      getLoginUserProfile()
      setEditMode(false)
    })
  }

  useEffect(() => {
    if (userProfile?.user_profile) {
      const { user_profile, email } = userProfile
      setValues({ ...user_profile, email })
      setData(formData)
      for (let key in user_profile) {
        if (user_profile[key]) {
          setValue(key, user_profile[key])
        }
      }
    }
  }, [userProfile?.user_profile])

  const renderFormData = () => {
    return data.map(
      ({ label, name, type, placeholder, rules, colSize }, index) => {
        return (
          <Col xs="12" lg={colSize}>
            <InputField
              key={index}
              label={label}
              name={name}
              type={type}
              value={values[name] || ""}
              onChange={e => {
                const { value } = e.target
                setValue(name, value)
                setValues(prevState => ({
                  ...prevState,
                  [name]: e.target.value
                }))
              }}
              placeholder={placeholder}
              control={control}
              trigger={trigger}
              rules={rules}
              disabled={name == "email" ? true : !editMode}
            />
          </Col>
        )
      }
    )
  }
  return (
    <CustomCard
      title="Contact Information"
      icon={
        <img
          src={assets.EditIcon}
          style={{ cursor: "pointer" }}
          onClick={() => setEditMode(true)}
        />
      }
      className="contact-info"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>{renderFormData()}</Row>
        {editMode && (
          <Row className="justify-content-end mt-3">
            <Col xs="4">
              <CustomButton
                label="Cancel"
                variant="outline-primary"
                type="button"
                onClick={() => {
                  getLoginUserProfile()
                  setEditMode(false)
                }}
              />
            </Col>
            <Col xs="4">
              <CustomButton
                label="Save"
                variant="primary"
                type="submit"
                loading={isLoading}
              />
            </Col>
          </Row>
        )}
      </form>
    </CustomCard>
  )
}
