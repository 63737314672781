import React from "react"
import { CustomCard } from "../../../components/CustomCard"
import { CustomButton } from "../../../components/CustomButton"
import { assets } from "../../../utils"

export const OpenOrdersCard = ({ title, open_orders, onClick }) => {
  return (
    <CustomCard
      title={title}
      subTitle={
        <div className="d-flex align-items-center">
          <h1
            style={{
              fontWeight: "700",
              fontSize: "40px",
              lineHeight: "18px"
            }}
          >
            {open_orders || 0}
          </h1>
          <p className="mb-1 ms-2 invisible">
            Orders That <br />
            Need Payment
          </p>
        </div>
      }
      src={assets.OpenOrdersIcon}
      className="payment-pending"
    >
      <p
        style={{
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "18px"
        }}
        className="invisible"
      >
        Total Value: $699.99
      </p>
      <CustomButton label="View All" backgroundColor="white" type="button" onClick={onClick} />
    </CustomCard>
  )
}
