import React, { useState, useEffect } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useNavigate } from "react-router-dom"
import { CustomContainer } from "../../../components/CustomContainer"
import { Col, Container, Row } from "react-bootstrap"
import { Filters } from "../../../components/Filters"
import { Search } from "../../../components/Search"
import { Chip } from "../../../components/Chip"
import { CustomSpinner } from "../../../components/CustomSpinner"
import { assets, debounce, getNextPage, getStockStatus } from "../../../utils"
import { OffCanvasExample } from "../../../components/CustomOffcanvas"
import { PriceListPopup } from "../../productdetails/priceListPopup"
import { useAuthContext } from "../../../contexts/AuthContext"
import { ConfirmPopup } from "../browseproducts/confirmPopup"
import { Products } from "../products"
import { useMyProductsContext } from "../../../contexts/MyProductsContext"
import { CustomButton } from "../../../components/CustomButton"
import { useDashboardContext } from "../../../contexts/DashboardContext"

export const MyProducts = ({ my_products }) => {
  const nav = useNavigate()
  const {
    isLoading,
    browseProducts,
    getBrowseProducts,
    brands,
    productTypes,
    filterParams,
    setFilterParams,
    setBrands,
    setProductTypes,
    product,
    getBrowseProduct,
    addProductToMyStore,
    removeProductFromMyStore,
    bulkDeleteMyProducts,
    showResetPriceUpdates,
    setShowResetPriceUpdates,
    updateInStock
  } = useMyProductsContext()
  const {
    isLoading: resetLoading,
    summary,
    getSummary,
    bestsellersActive,
    setBestSellersActive,
    prieUpdatesActive,
    setPrieUpdatesActive,
    resetPriceUpdates
  } = useDashboardContext()
  const { notifySuccess } = useAuthContext()
  const { next, results } = browseProducts
  const [show, setShow] = useState(false)
  const [showPricePopup, setShowPricePopup] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleClosePricePopup = () => setShowPricePopup(false)
  const [productId, setProductId] = useState(null)
  const [idToRemove, setIdToRemove] = useState(null)
  const [showConfirm, setShowConfirm] = useState(false)
  const onHideConfirm = () => {
    setIdToRemove(null)
    setShowConfirm(false)
  }
  const [selectedProducts, setSelectedProducts] = useState([])
  const [showConfirmRemove, setShowConfirmRemove] = useState(false)
  const onHideConfirmRemove = () => {
    setShowConfirmRemove(false)
  }

  const loadMoreProducts = params => {
    const {
      page,
      price_min,
      price_max,
      product_type,
      vendor,
      search,
      my_products,
      bestseller,
      price_updates
    } = params
    const config = {
      params: {
        my_products,
        bestseller,
        price_updates,
        page,
        search,
        price_min,
        price_max,
        in_stock: getStockStatus(params),
        vendor: vendor?.join(","),
        product_type: product_type?.join(",")
      }
    }
    getBrowseProducts(config)
  }

  const loadMoreHandler = () => {
    if (next) {
      const page = getNextPage(next)
      loadMoreProducts({ ...filterParams, page })
    }
  }

  const onClickProduct = id => {
    if (my_products) {
      return nav(`/my-products/${id}`, {
        state: {
          price_updates: filterParams.price_updates
        }
      })
    }
    nav(`/browse-products/${id}`)
  }

  /* Filter Utility **/
  const onSubmit = vals => {
    setFilterParams(() => ({ ...filterParams, ...vals }))
  }

  const onChangeHandler = vals => {
    const { checked, name, value } = vals
    if (!checked) {
      filterParams[name] = filterParams[name]?.filter(val => val !== value)
    } else {
      filterParams[name]?.push(value)
    }
    setFilterParams(() => ({ ...filterParams }))
  }

  const onChangeSearch = debounce(
    text => setFilterParams({ ...filterParams, search: text }),
    300
  )

  const onClickInStock = checked => {
    setFilterParams({ ...filterParams, inStock: checked })
  }

  const onClickOutOfStock = checked => {
    setFilterParams({ ...filterParams, outOfStock: checked })
  }

  useEffect(() => {
    loadMoreProducts({ ...filterParams, page: 1 })
  }, [filterParams])

  /* END **/

  /** Submit prices */
  const onSubmitPrices = vals => {
    vals.product = product.id
    addProductToMyStore(vals).then(() => {
      setShowPricePopup(false)
      notifySuccess("Products added successfully!")
    })
  }

  /** Click Remove */
  const onClickRemoveHandle = () => {
    bulkDeleteMyProducts(selectedProducts).then(() => {
      setShowConfirmRemove(false)
      notifySuccess("Products removed successfully!")
      setSelectedProducts([])
    })
  }

  useEffect(() => {
    if (productId) {
      getBrowseProduct(productId).then(() => {
        setShowPricePopup(true)
        setProductId(null)
      })
    }
  }, [productId])

  useEffect(() => {
    getSummary()
  }, [])

  const handleInventorySync = () => {
    updateInStock({}).then(() => {
      setShowPricePopup(false)
      notifySuccess("Inventory syncing started successfully!")
    })
  }

  return (
    <Container fluid className="BrowseProducts">
      <Row>
        <Col
          md="3"
          lg="3"
          xl="2"
          id="filters-wrapper"
          className="p-0 d-none d-md-block"
        >
          <OffCanvasExample
            key={"idx12345"}
            placement={"end"}
            name={"end"}
            className="d-md-none"
            show={show}
            handleShow={handleShow}
            handleClose={handleClose}
          >
            <Filters
              priceMin={filterParams.price_min}
              priceMax={filterParams.price_max}
              brands={brands}
              productTypes={productTypes}
              onSubmit={onSubmit}
              inStock={filterParams.inStock}
              outOfStock={filterParams.outOfStock}
              onClickInStock={onClickInStock}
              onClickOutOfStock={onClickOutOfStock}
              onChangeHandler={onChangeHandler}
              setBrands={setBrands}
              setProductTypes={setProductTypes}
              showInStock={true}
            />
          </OffCanvasExample>
          <div
            className="filters-container"
            style={{
              maxHeight: "100vh",
              overflow: "hidden",
              overflowY: "auto"
            }}
          >
            <Filters
              priceMin={filterParams.price_min}
              priceMax={filterParams.price_max}
              brands={brands}
              productTypes={productTypes}
              onSubmit={onSubmit}
              inStock={filterParams.inStock}
              outOfStock={filterParams.outOfStock}
              onClickInStock={onClickInStock}
              onClickOutOfStock={onClickOutOfStock}
              onChangeHandler={onChangeHandler}
              setBrands={setBrands}
              setProductTypes={setProductTypes}
              showInStock={true}
            />
          </div>
        </Col>
        <Col md="9" lg="9" xl="10" id="page-content-wrapper" className="p-0">
          <CustomContainer title="">
            <Row>
              <Col xs="10" md="7">
                <h3 className="header-title mb-2">My Products</h3>
                <p className="mb-0" style={{ color: "#666666" }}>
                  You currently have{" "}
                  <span style={{ color: "#27963C", fontWeight: "500" }}>
                    {`${summary.total_products || 0} Products `}
                  </span>
                  synced to your store
                </p>
              </Col>
              <Col xs="2" className="d-block d-md-none">
                <img src={assets.FilterIcon} onClick={handleShow} />
              </Col>
              <Col xs="12" md="2">
                <CustomButton
                  label="Sync Inventory"
                  variant="outline-primary"
                  backgroundColor="white"
                  styles={{ width: "100%",boxShadow: 'none' }}
                  onClick={handleInventorySync}
                />
              </Col>
              <Col xs="12" md="3" className="mb-3">
                <Search onChange={onChangeSearch} value={filterParams.search} />
              </Col>
            </Row>
            <Row
              className={`add-btn-row mb-2 ${
                selectedProducts.length > 0 ? "visible" : "visible"
              }`}
            >
              {showResetPriceUpdates && (
                <Col xs="6" md="4" lg="2" className=" mb-3">
                  <CustomButton
                    label="Mark as Read"
                    variant="outline-primary"
                    disabled={resetLoading}
                    onClick={() => {
                      resetPriceUpdates().then(() => {
                        setShowResetPriceUpdates(false)
                        setPrieUpdatesActive(false)
                        getSummary()
                      })
                    }}
                  />
                </Col>
              )}

              <Col xs="6" md="4" lg="1" className=" mb-3">
                <CustomButton
                  label="Remove"
                  className={`${
                    selectedProducts.length > 0 ? "visible" : "invisible"
                  }`}
                  disabled={isLoading}
                  onClick={() => setShowConfirmRemove(true)}
                />
              </Col>
              <Col xs="6" md="4" lg="1" className=" mb-3">
                <CustomButton
                  label="Reset"
                  variant="outline-primary"
                  className={`ms-3 ${
                    selectedProducts.length > 0 ? "visible" : "invisible"
                  }`}
                  disabled={isLoading}
                  onClick={() => setSelectedProducts([])}
                />
              </Col>
              {/* <Col xs="6" md="4" lg="1" className="ms-auto mb-3">
                <Chip
                  label={`Out of Stock: ${summary.out_of_stock || 0}`}
                  className="invisible"
                />
              </Col> */}
              <Col xs="6" md="4" lg="2" xl="2" xxl="1" className="ms-auto mb-3">
                <Chip
                  label={`Price Updates: ${summary.price_updates || 0}`}
                  className={prieUpdatesActive ? "active" : ""}
                  onClick={() => {
                    if (!prieUpdatesActive) {
                      setPrieUpdatesActive(true)
                      setBestSellersActive(false)
                    } else {
                      setPrieUpdatesActive(false)
                      setShowResetPriceUpdates(false)
                    }
                  }}
                />
              </Col>
              <Col xs="6" md="4" lg="2" xl="2" xxl="1" className=" mb-3">
                <Chip
                  label="Bestsellers"
                  className={bestsellersActive ? "active" : ""}
                  onClick={() => {
                    if (!bestsellersActive) {
                      setBestSellersActive(true)
                      setPrieUpdatesActive(false)
                    } else {
                      setBestSellersActive(false)
                    }
                  }}
                />
              </Col>
            </Row>
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMoreHandler}
              hasMore={true}
              threshold={250}
              useWindow={false}
              initialLoad={false}
            >
              <Products
                products={results}
                onClickProduct={onClickProduct}
                setProductId={setProductId}
                setIdToRemove={setIdToRemove}
                setShowConfirm={setShowConfirm}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                myProducts={true}
              />
            </InfiniteScroll>
          </CustomContainer>
        </Col>
      </Row>
      {product?.id && (
        <PriceListPopup
          key={product.id}
          show={showPricePopup}
          onHide={handleClosePricePopup}
          data={[...product.product_catalog_variants]}
          loading={isLoading}
          onSubmit={onSubmitPrices}
        />
      )}
      <ConfirmPopup
        title="Remove Product"
        subTitle="Are you sure want to remove this product ?"
        key={idToRemove}
        show={showConfirm}
        onHide={onHideConfirm}
        isLoading={isLoading}
        btnTitle="Remove"
        onClick={() => {
          removeProductFromMyStore(idToRemove).then(() => {
            setIdToRemove(null)
            setShowConfirm(false)
          })
        }}
      />
      <ConfirmPopup
        title="Remove Product"
        subTitle="Are you sure want to remove these ?"
        key={"showConfirmRemove"}
        show={showConfirmRemove}
        onHide={onHideConfirmRemove}
        isLoading={isLoading}
        btnTitle="Remove"
        onClick={onClickRemoveHandle}
      />
      <CustomSpinner loading={isLoading || resetLoading} />
    </Container>
  )
}
