import React from "react"
import { CustomCard } from "../../../components/CustomCard"
import { Col, Row } from "react-bootstrap"
import { assets } from "../../../utils"
import { Link } from "react-router-dom"

export const Terms = () => {
  return (
    <CustomCard title="" className="terms">
      <Link to="/terms-of-service" target="_blank">
        <Row className="align-items-center">
          <Col xs="3">
            <img src={assets.TermsIcon} />
          </Col>
          <Col xs="9">
            <h5 className="title">Terms & Conditions</h5>
          </Col>
        </Row>
      </Link>
    </CustomCard>
  )
}

export const Privacy = () => {
  return (
    <CustomCard title="" className="terms">
      <Link to="/privacy-policy" target="_blank">
        <Row className="align-items-center">
          <Col xs="3">
            <img src={assets.PrivacyIcon} />
          </Col>
          <Col xs="9">
            <h5 className="title">Privacy Policy</h5>
          </Col>
        </Row>
      </Link>
    </CustomCard>
  )
}
