import React, { useEffect, useState } from "react"
import { AuthContainer } from "../../common/AuthContainer"
import { Subscription } from "./subscription"
import { Col, Container, Row } from "react-bootstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import { useUserProfileContext } from "../../contexts/UserProfileContext"
import { CustomSpinner } from "../../components/CustomSpinner"
import { CustomModal } from "../../components/CustomModal"

export const Subscriptions = () => {
  const nav = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [show, setShow] = useState(false)
  const onHide = () => {
    setShow(false)
    setTimeout(() => nav("/dashboard"), 0)
  }
  const [loader, setLoader] = useState(true)

  const {
    isLoading,
    userProfile,
    getLoginUserProfile,
    subscriptions,
    getSubscriptions,
    addSubscriptionRequest
  } = useUserProfileContext()
  const onClickSubscription = id => {
    if (userProfile?.user_store?.id) {
      const { is_subscribed } = userProfile
      
      if (!is_subscribed) {
        const payload = { tier: id }
        return addSubscriptionRequest(payload).then(response => {
          const { is_subscribed, charge_response } = response

          if (charge_response?.confirmation_url) {
            window.location.href = charge_response.confirmation_url
          }

          if (!is_subscribed) {
            return nav("/subscription")
          }
          nav("/dashboard")
        })
      }
      nav("/dashboard")
    }
  }

  useEffect(() => {
    if (searchParams.get("charge_id")) {
      setTimeout(() => {
        setLoader(false)
        getSubscriptions()
        getLoginUserProfile()
      }, 5000)
    } else {
      setLoader(false)
      getSubscriptions()
      getLoginUserProfile()
    }
  }, [])

  useEffect(() => {
    if (userProfile?.id && !loader) {
      console.log("userProfileSubscription", userProfile)
      const { is_subscribed } = userProfile
      if (is_subscribed && searchParams.get("charge_id")) {
        setTimeout(() => setShow(true), 0)
      } else if (is_subscribed) {
        setTimeout(() => nav("/dashboard"), 0)
      }
    }
  }, [userProfile, loader])

  return (
    <AuthContainer className="subscriptions">
      <Container className="mt-3">
        <Row>
          {subscriptions.map((subscription, index) => (
            <Col xs={12} lg={3} className="mb-4" key={index}>
              <Subscription
                {...subscription}
                name={subscription.name}
                onClickSubscription={onClickSubscription}
                mt="mt-2"
              />
            </Col>
          ))}
          {subscriptions.length > 0 && (
            <Col className="text-center">
              <p>
                Are you a supplier? <Link to="/contact-us">Contact Us</Link> to
                learn more about your own branded retailer portal!
              </p>
            </Col>
          )}
        </Row>
      </Container>
      <CustomModal
        size="md"
        headerTitle="Success"
        showHeader={true}
        footerBtnText="Ok"
        showFooter={true}
        show={show}
        onHide={onHide}
      >
        <p>
          Your transaction is successfull! Please click ok to go to
          dashboard.
        </p>
      </CustomModal>
      <CustomSpinner loading={isLoading || loader} />
    </AuthContainer>
  )
}
