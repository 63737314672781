import { useRoutes } from "react-router-dom"
import { Login } from "./pages/login/login"
import { Signup } from "./pages/signup/signup"
import DashboardContainer from "./pages/DashboardContainer"
import { PrivateRoute } from "./utils/PrivateRoute"
import { Subscriptions } from "./pages/subscriptions"
import { Payment } from "./pages/payment/payment"
import { ForgetPassword } from "./pages/forgetpassword/forgetpassword"
import { ResetPassword } from "./pages/resetpassword/resetpassword"
import { StoryBook } from "./pages/StoryBook"
import { ChangePassword } from "./pages/changepassword/changepassword"
import { AccountSettings } from "./pages/settings"
import { Dashboard } from "./pages/DashboardContainer/dashboard"
import { MyProducts } from "./pages/DashboardContainer/myproducts"
import { LinkShopify } from "./pages/linkshopify"
import { BrowseProducts } from "./pages/DashboardContainer/browseproducts"
import { ProductDetails } from "./pages/productdetails"
import { PrivacyPolicy } from "./pages/privacypolicy"
import { TermsOfService } from "./pages/termsofservice"
import { Orders } from "./pages/DashboardContainer/order"
import { OrdersDetails } from "./pages/ordersdetails"
import { StatisticsChart } from "./pages/DashboardContainer/dashboard/StatisticsChart"
import { AdminUserList } from "./pages/admin"

// const Orders = () => <h1>Coming Soon!</h1>
const Support = () => <h1>Coming Soon!</h1>

const AppRoutes = () => {
  const element = useRoutes([
    { path: "storybook", element: <StoryBook /> },
    { path: "signup", element: <Signup /> },
    { path: "login", element: <Login /> },
    { path: "forget-password", element: <ForgetPassword /> },
    { path: "reset-password-confirm", element: <ResetPassword /> },
    {
      path: "link-shopify-app",
      element: (
        <PrivateRoute>
          <LinkShopify />
        </PrivateRoute>
      )
    },
    {
      path: "payment",
      element: (
        <PrivateRoute>
          <Payment />
        </PrivateRoute>
      )
    },

    {
      path: "subscription",
      element: (
        <PrivateRoute>
          <Subscriptions />
        </PrivateRoute>
      )
    },

    {
      path: "",
      element: (
        <PrivateRoute>
          <DashboardContainer />
        </PrivateRoute>
      ),
      children: [
        { path: "dashboard", element: <Dashboard /> },
        { path: "my-products", element: <MyProducts my_products={true} /> },
        {
          path: "my-products/:id",
          element: <ProductDetails my_products={true} />
        },
        { path: "browse-products", element: <BrowseProducts /> },
        { path: "browse-products/:id", element: <ProductDetails /> },
        { path: "orders", element: <Orders /> },
        { path: "orders/:id", element: <OrdersDetails /> },
        { path: "support", element: <Support /> },
        { path: "change-password", element: <ChangePassword /> },
        { path: "settings", element: <AccountSettings /> },
        { path: "chart", element: <StatisticsChart /> }
      ]
    },
    {
      path: "privacy-policy",
      element: <PrivacyPolicy />
    },
    {
      path: "terms-of-service",
      element: <TermsOfService />
    },
    {
      path: "users",
      element: (
        <PrivateRoute>
          <AdminUserList />
        </PrivateRoute>
      )
    }
  ])

  return element
}

export default AppRoutes
