import React from "react"
import { Col, Row } from "react-bootstrap"
import { CustomModal } from "../CustomModal"
import { assets } from "../../utils"
import { CustomButton } from "../CustomButton"

export const InfoPopup = ({
  size,
  title,
  subTitle,
  show,
  onHide,
  isLoading,
  btnTitle,
  onClick,
  onClickLink
}) => {
  return (
    <CustomModal
      size={size}
      showHeader={true}
      headerTitle={title}
      show={show}
      onHide={onHide}
    >
      <Row>
        <Col xs="12" className="mb-4">
          {false && (
            <h6 className="title">
              <img src={assets.ErrorIcon} /> {title}
            </h6>
          )}
        </Col>
        <Col xs="12" className="mb-4">
          <p className="sub-title text-nowrap">{subTitle}</p>
        </Col>
      </Row>
      <Row className="add-card-payment justify-content-center mb-3">
        <Col xs="6">
          <CustomButton
            label={btnTitle}
            variant="primary"
            type="button"
            disabled={isLoading}
            loading={isLoading}
            onClick={onClick}
          />
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <p>
            Want to change your default card?{" "}
            <span
              onClick={onClickLink}
              style={{ color: "#27963C", cursor: "pointer" }}
            >
              Click Here
            </span>
          </p>
        </Col>
      </Row>
    </CustomModal>
  )
}
