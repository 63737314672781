import React, { useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { Col, Row } from "react-bootstrap"
import { InputField } from "../../components/InputField"
import { CustomButton } from "../../components/CustomButton"
import { useAuthContext } from "../../contexts/AuthContext"

export const ShopURL = () => {
  const { getAppUrlToInstall } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const formSchema = Yup.object().shape({
    shop_url: Yup.string().required("Shopify shop url is required")
  })
  const formOptions = { resolver: yupResolver(formSchema) }
  const { handleSubmit, control, trigger } = useForm(formOptions)
  const onSubmit = vals => {
    setLoading(true)
    const config = { params: { ...vals } }
    getAppUrlToInstall(config).then(rediectUrl => {
      setLoading(false)
      if (rediectUrl) {
        window.open(rediectUrl)
      }
    })
  }

  return (
    <div className="shop-url-to-install">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="align-items-center">
          <Col xs="8" md="9">
            <InputField
              key="shop_url"
              label=""
              name="shop_url"
              placeholder="Shopify Domain (i.e. route-store.myshopify.com)"
              control={control}
              trigger={trigger}
            />
          </Col>
          <Col xs="4" md="3">
            <CustomButton
              label="Install"
              variant="primary"
              type="submit"
              loading={loading}
            />
          </Col>
        </Row>
      </form>
    </div>
  )
}
