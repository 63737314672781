import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { Controller } from "react-hook-form"
import Select from "react-select"
import "./index.scss"

export const CustomSelect = ({
  label,
  name,
  options,
  defaultValue,
  control,
  rules,
  ...moreProps
}) => {
  const [selectedOption, setSelectedOption] = useState(null)

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, onBlur, ref },
        fieldState: { invalid, isTouched, isDirty, error }
      }) => {
        return (
          <Form.Group
            className="is-invalid react-select mb-3 position-relative"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label>{label}</Form.Label>
            <Select
              label={label}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              isInvalid={invalid}
              defaultValue={defaultValue}
              options={options}
              {...moreProps}
              classNamePrefix={`custom-select ${invalid ? "is-invalid" : ""}`}
            />

            {error && invalid && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                {error.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        )
      }}
    />
  )
}
