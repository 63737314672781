import { Row, Col, Container } from "react-bootstrap"
import { useAuthContext } from "../../contexts/AuthContext"
import { useEffect } from "react"
import { stringToHTML } from "../../utils"
import { CustomSpinner } from "../../components/CustomSpinner"

export const TermsOfService = () => {
  const { isLoading, terms, getTerms } = useAuthContext()

  useEffect(() => {
    getTerms()
  }, [])

  return (
    <Container>
      <Row className="content p-3">
        <Col>{terms && stringToHTML(terms)}</Col>
      </Row>
      <CustomSpinner loading={isLoading} />
    </Container>
  )
}
