import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { assets, expiryDateFormat } from "../../utils"
import { CustomButton } from "../CustomButton"
import "./CreditCard.scss"

export const CreditCard = ({
  card_type,
  customer,
  customer_payment_profile_id,
  expiration_date,
  id,
  is_default,
  mask_card_number,
  isLoading,
  onClickRemove = () => {},
  onClickDefault = () => {}
}) => {
  return (
    <Row className="credit-card p-3">
      <Col xs="10" className="mb-3">
        <img src={assets.VisaIcon} />
      </Col>
      <Col xs="2" className="mb-3">
        <Form.Check
          type="checkbox"
          id={`default-credit-card`}
          checked={is_default}
          disabled={isLoading}
          onClick={() => {
            if (!is_default) {
              onClickDefault(id)
            }
          }}
        />
      </Col>

      <Col xs="12">
        <h5 className="title">Card Number</h5>
        <p className="value">
          {mask_card_number
            ? `**** **** **** ${mask_card_number.slice(4, 8)}`
            : "N/A"}
        </p>
      </Col>
      <Col xs="12">
        <h5 className="title">Expiration Date</h5>
        <p className="value">{expiryDateFormat(expiration_date)}</p>
      </Col>
      <Col xs="12">
        {!is_default && (
          <CustomButton
            type="button"
            label="Remove"
            backgroundColor="white"
            onClick={() => onClickRemove(id)}
          />
        )}
      </Col>
    </Row>
  )
}
