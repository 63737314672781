import React from "react"
import { Form, InputGroup } from "react-bootstrap"
import { Controller } from "react-hook-form"
import { CustomDatePicker } from "../CustomDatePicker"
import "./InputField.scss"

export const InputField = ({
  label,
  name,
  type,
  placeholder,
  control,
  trigger,
  rules,
  // defaultValue,
  ...moreProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      // defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, name, ref },
        formState: { errors }
      }) => {
        // console.log("errors", errors)
        return (
          <Form.Group className="is-invalid mb-3">
            <Form.Label>{label}</Form.Label>
            {type === "date" ? (
              <CustomDatePicker
                {...moreProps}
                className={`${errors[name] ? "is-invalid" : ""}`}
                onChange={onChange}
                onBlur={onBlur}
                // ref={ref}
              />
            ) : (
              <Form.Control
                type={type}
                placeholder={placeholder}
                onChange={async value => {
                  onChange(value)
                  trigger(name)
                }}
                onBlur={onBlur}
                ref={ref}
                className={`${errors[name] ? "is-invalid" : ""}`}
                {...moreProps}
              />
            )}

            {errors[name]?.message && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                {errors[name]?.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        )
      }}
    />
  )
}

export const InputFormGroup = ({
  label,
  name,
  index,
  type,
  placeholder,
  control,
  trigger,
  rules,
  ...moreProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, name, ref },
        formState: { errors }
      }) => {
        return (
          <InputGroup className="is-invalid price-list-input mb-3">
            <InputGroup.Text>$</InputGroup.Text>

            <Form.Control
              type={type}
              placeholder={placeholder}
              onChange={async value => {
                onChange(value)
                trigger(name)
              }}
              onBlur={onBlur}
              ref={ref}
              className={`${
                errors?.my_store_product_variants?.[index]?.price?.message
                  ? "is-invalid"
                  : ""
              }`}
              {...moreProps}
            />

            {errors?.my_store_product_variants?.[index]?.price?.message && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                {errors.my_store_product_variants?.[index]?.price?.message}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        )
      }}
    />
  )
}
